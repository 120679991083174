import styled from 'styled-components';

export const StyledAppContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.appBackgroundColors['web']};

  @media (max-width: 599px) {
    background-color: ${(props) => props.appBackgroundColors['mobile']};
  }

  @media (min-width: 600px) and (max-width: 1023px) {
    background-color: ${(props) => props.appBackgroundColors['tablet']};
  }
`;
