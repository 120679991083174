import sites from './sites';

export const siteRoutes = Object.freeze({
  [sites.LOGIN]: 'logg-inn',
  [sites.SELECT_PRODUCER]: 'velg-produsent',
  [sites.HERD]: 'besetning',
  [sites.ANIMAL]: 'individ',
  [sites.ANIMAL_NOTES]: 'individ-notat',
  [sites.DELIVERIES]: 'leveringer',
  [sites.FEED_SETTINGS]: 'forinnstillinger',
  [sites.KPI_PANEL]: 'nokkeltall',
  [sites.TEST_RESULTS]: 'provesvar',
  [sites.OTHER_TOOLS]: 'andre-verktoy',
  [sites.REGISTER_ENROLLMENT]: 'kjop',
  [sites.REGISTER_UNENROLLMENT]: 'utmelding',
  [sites.REGISTER_PARTURITION]: 'kalving',
  [sites.REGISTER_MILK_AND_CONCENTRATE_WEIGHINGS]: 'mjolk-og-kraftfor'
});

export const appMenuLang = {
  HERD: 'Besetning',
  TEST_RESULTS: 'Prøvesvar',
  DELIVERIES: 'Leveringer',
  KPI_PANEL: 'Nøkkeltall',
  FEED_SETTINGS: 'Fôrinstillinger',
  TBS: 'Bedriftsstyring'
};

export const menuTriggerButtonAriaLabel = 'Meny knapp';

export const productName = 'Ku';

export const productMenuLang = {
  COW: 'Eana ku',
  FIELD_ASSISTANT: 'Eana Skifte'
};

export const otherProductsRoutes = {
  FIELD_ASSISTANT: 'https://skifte.eana.no/'
};

export const otherProductsMenuLang = {
  EANA_PRODUCTS_MENU_TITLE: 'Flere Eana produkter',
  FIELD_ASSISTANT: {
    PRODUCTS_MENU_TITLE: 'Eana Skifte (gratis)',
    PRODUCTS_MENU_DESCRIPTION:
      'Eana Skifte forenkler den krevende loggføringen av arbeidet som gjøres på skiftene for å gi deg bedre oversikt.'
  }
};

export const breadCrumbSiteNames = Object.freeze({
  [sites.LOGIN]: 'Logg inn',
  [sites.HERD]: 'Besetning',
  [sites.ANIMAL]: 'Besetning',
  [sites.ANIMAL_NOTES]: 'Besetning',
  [sites.DELIVERIES]: 'Leveringer',
  [sites.FEED_SETTINGS]: 'Fôrinnstillinger',
  [sites.KPI_PANEL]: 'Nøkkeltall',
  [sites.TEST_RESULTS]: 'Prøvesvar',
  [sites.REGISTER_ENROLLMENT]: 'Besetning',
  [sites.REGISTER_UNENROLLMENT]: 'Besetning',
  [sites.REGISTER_PARTURITION]: 'Besetning',
  [sites.REGISTER_MILK_AND_CONCENTRATE_WEIGHINGS]: 'Besetning'
});

export const breadCrumbScreenNames = Object.freeze({
  [sites.HERD]: Object.freeze({
    GROUP_REGISTRATION: 'Grupperegistrering'
  }),
  [sites.ANIMAL]: Object.freeze({
    DEFAULT: 'Individ'
  }),
  [sites.ANIMAL_NOTES]: Object.freeze({
    DEFAULT: 'Individ'
  }),
  [sites.DELIVERIES]: Object.freeze({}),
  [sites.KPI_PANEL]: Object.freeze({}),
  [sites.TEST_RESULTS]: Object.freeze({}),
  [sites.FEED_SETTINGS]: Object.freeze({}),
  [sites.REGISTER_ENROLLMENT]: Object.freeze({
    DEFAULT: 'Kjøp'
  }),
  [sites.REGISTER_UNENROLLMENT]: Object.freeze({
    DEFAULT: 'Individ'
  }),
  [sites.REGISTER_PARTURITION]: Object.freeze({
    DEFAULT: 'Individ'
  }),
  [sites.REGISTER_MILK_AND_CONCENTRATE_WEIGHINGS]: Object.freeze({
    DEFAULT: 'Grupperegistrering'
  })
});
