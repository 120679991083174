const norwegian = {
  LOADING: 'Logger deg inn...',

  ERROR_BOUNDARY_HEADER: 'Kan ikke vise innholdet',
  ERROR_BOUNDARY_BODY:
    'Vi har for øyeblikket problemer. Vennligst prøv igjen senere.',
  ERROR_BOUNDARY_META_INFORMATION:
    "Ved vedvarende problemer, ta kontakt med oss. <abbr title='Telefon'>Tlf</abbr>: <a href='tel:51371500'>51 37 15 00</a>" +
    " — e-post: <a href='mailto:medlem@tine.no'>medlem@tine.no</a>."
};

export default norwegian;

export { norwegian };
