import styled from 'styled-components';
import Typography from '../../../styles/typography';

const StyledText = styled.span`
  font-family: ${Typography.text.name};
  color: ${(props) => props.color};
  font-style: ${(props) => (props.italic ? 'italic' : 'normal')};
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.size};
`;

export default StyledText;
