const Typography = {
  text: {
    name: 'Open Sans, sans-serif',
    sizes: {
      extrasmall: '0.625rem',
      small: '0.75rem',
      normal: '0.875rem',
      large: '1rem',
      larger: '1.125rem',
      extralarge: '1.25rem'
    },
    normal: '400',
    light: '300',
    semibold: '600',
    bold: '700',
    italic: 'italic'
  },
  number: {
    name: 'PT Serif, serif',
    size: '1rem',
    normal: '400',
    bold: '700',
    italic: 'italic'
  }
};

/**
 * @component
 */
export default Typography;
