const isDevEnvironment =
  window.location.href.indexOf('-dev') > -1 ||
  window.location.href.indexOf('localhost') > -1;

export default isConsultant => {
  if (isDevEnvironment) {
    if (isConsultant) {
      return 'https://medlem-qa.tine.no/redirect/?source=tbs-start&connection=TineISAM';
    } else {
      return 'https://medlem-qa.tine.no/redirect/?source=tbs-start&connection=prodreg';
    }
  } else {
    if (isConsultant) {
      return 'https://medlem.tine.no/redirect/?source=tbs-start&connection=TineISAM';
    } else {
      return 'https://medlem.tine.no/redirect/?source=tbs-start&connection=prodreg';
    }
  }
};
