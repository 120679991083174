export const HERD_SCREENS = Object.freeze({
  DEFAULT: 'DEFAULT',
  GROUP_REGISTRATION: 'GROUP_REGISTRATION'
});

export const DEFAULT_SCREEN = Object.freeze({
  DEFAULT: 'DEFAULT'
});

export default DEFAULT_SCREEN;
