const sites = Object.freeze({
  LOGIN: 'LOGIN',
  SELECT_PRODUCER: 'SELECT_PRODUCER',
  HERD: 'HERD',
  ANIMAL: 'ANIMAL',
  ANIMAL_NOTES: 'ANIMAL_NOTES',
  KPI_PANEL: 'KPI_PANEL',
  DELIVERIES: 'DELIVERIES',
  FEED_SETTINGS: 'FEED_SETTINGS',
  TEST_RESULTS: 'TEST_RESULTS',
  OTHER_TOOLS: 'OTHER_TOOLS',
  REGISTER_ENROLLMENT: 'REGISTER_ENROLLMENT',
  REGISTER_UNENROLLMENT: 'REGISTER_UNENROLLMENT',
  REGISTER_PARTURITION: 'REGISTER_PARTURITION',
  REGISTER_MILK_AND_CONCENTRATE_WEIGHINGS:
    'REGISTER_MILK_AND_CONCENTRATE_WEIGHINGS'
});

export default sites;
