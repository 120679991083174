import React from 'react';
import PropTypes from 'prop-types';
import { StyledSpinWrapper, StyledWrapper } from './styled-components';

const SpinWrapper = ({ children }) => (
  <StyledWrapper>
    <StyledSpinWrapper>{children}</StyledSpinWrapper>
  </StyledWrapper>
);

SpinWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default SpinWrapper;
