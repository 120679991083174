import React from 'react';
import PropTypes from 'prop-types';

import GrowingGras from './growing-gras.svg';
import IconWrapper from '../icon-wrapper';

/**
 * [See current version in abstract]
 * (https://share.goabstract.com/c77bd097-b471-43c1-8e98-d4568c22ade7)
 */
export default function GrowingGrasIcon({ scale, altText }) {
  return (
    <IconWrapper width={2.15 * scale} height={0.9 * scale} aria-label={altText}>
      <GrowingGras />
    </IconWrapper>
  );
}

GrowingGrasIcon.propTypes = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale: PropTypes.number,
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: PropTypes.string
};

GrowingGrasIcon.defaultProps = {
  scale: 2
};
