import styled, { css } from 'styled-components';
import Colors from '../../../styles/colors/colors';

const iconColors = {
  normal: {
    circle: Colors.primary,
    icon: Colors.white,
    focus: Colors.primaryShadeLight,
    hover: Colors.primaryShadeLight,
    active: Colors.primaryShadeDark
  },
  attention: {
    circle: Colors.attention,
    icon: Colors.white,
    focus: Colors.attentionShadeLight,
    hover: Colors.attentionShadeLight,
    active: Colors.attentionShadeLighter
  },
  negative: {
    circle: Colors.negative,
    icon: Colors.white,
    focus: Colors.negativeShadeLight,
    hover: Colors.negativeShadeLight,
    active: Colors.negativeDark
  },
  negativeShadeLight: {
    circle: Colors.negativeShadeLight,
    icon: Colors.white,
    focus: Colors.negativeShadeLightest,
    hover: Colors.negativeShadeLightest,
    active: Colors.negativeDark
  }
};

const getStyle = (colorScheme) => {
  return {
    fillIcon: iconColors[colorScheme].icon,
    fillCircle: iconColors[colorScheme].circle,
    hover: iconColors[colorScheme].hover,
    active: iconColors[colorScheme].active,
    focus: iconColors[colorScheme].focus
  };
};

export const AlertIconStyle = styled.div`
  svg {
    .alert-cls-1 {
      fill: ${(props) =>
        (props.colorScheme && getStyle(props.colorScheme).fillIcon) ||
        iconColors.normal.icon};
    }
    circle {
      fill: ${(props) =>
        (props.colorScheme && getStyle(props.colorScheme).fillCircle) ||
        iconColors.normal.circle};
    }
  }

  :focus {
    outline: none;

    circle {
      fill: ${(props) =>
        (props.colorScheme && getStyle(props.colorScheme).focus) ||
        iconColors.normal.hover};
    }
  }

  :hover {
    circle {
      fill: ${(props) =>
        props.hover &&
        ((props.colorScheme && getStyle(props.colorScheme).hover) ||
          iconColors.normal.hover)};
    }
  }
  :active {
    circle {
      fill: ${(props) =>
        props.active &&
        ((props.colorScheme && getStyle(props.colorScheme).active) ||
          iconColors.normal.active)};
    }
  }
`;

export const AlertIconHover = css`
  svg {
    .alert-cls-1 {
      fill: ${Colors.white};
    }
    circle {
      fill: ${Colors.negativeShadeLight};
    }
  }
`;

export const AlertIconClick = css`
  svg {
    .alert-cls-1 {
      fill: ${Colors.white};
    }
    circle {
      fill: ${Colors.secondaryShadeDark};
    }
  }
`;

export const AlertIconAttention = css`
  svg {
    .alert-cls-1 {
      fill: ${Colors.white};
    }
    circle {
      fill: ${Colors.attention};
    }
  }
`;

export const AlertIconNeutral = css`
  svg {
    .alert-cls-1 {
      fill: ${Colors.white};
    }
    circle {
      fill: ${Colors.primary};
    }
  }
`;

export const AlertIconNegativeShadeLight = css`
  svg {
    .alert-cls-1 {
      fill: ${Colors.white};
    }
    circle {
      fill: ${Colors.negativeShadeLight};
    }
  }
`;

export const AlertIconNegative = css`
  svg {
    .alert-cls-1 {
      fill: ${Colors.white};
    }
    circle {
      fill: ${Colors.negative};
    }
  }
`;
