import decodeToken from '../decode-token';

import storageKeys from '../constants/storage-keys';

const getAccessToken = () => {
  return localStorage.getItem(storageKeys.accessToken);
};

export default (key) => {
  const token = getAccessToken();

  if (token && token !== '') {
    try {
      const parsedToken = decodeToken(token);
      return parsedToken[key];
    } catch (e) {
      throw new Error(
        `Unable to decode access token in AuthClient#getAccessTokenInformation with key=:${key}: ${
          e && e.message
        }`
      );
    }
  } else {
    return null;
  }
};
