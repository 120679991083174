import styled from 'styled-components';
import Colors from '../../../styles/colors';

const iconColors = {
  black: {
    text: Colors.black,
    icon: Colors.black
  },
  white: {
    text: Colors.white,
    icon: Colors.white
  },
  negative: {
    text: Colors.white
  }
};

const getStyle = (colorScheme) => {
  return {
    fillIcon: iconColors[colorScheme].icon,
    fillText: iconColors[colorScheme].text
  };
};

export const MimiroVerticalIconStyle = styled.div`
  svg {
    .mimiro-vertical-icon-cls-1 {
      fill: ${(props) =>
        props.colorScheme && getStyle(props.colorScheme).fillText} !important;
    }

    .mimiro-vertical-icon-cls-2 {
      fill: ${(props) =>
        props.colorScheme && getStyle(props.colorScheme).fillIcon} !important;
    }

    .mimiro-vertical-icon-cls-3 {
      fill: ${(props) =>
        props.colorScheme && getStyle(props.colorScheme).fillIcon} !important;
    }

    .mimiro-vertical-icon-cls-4 {
      fill: ${(props) =>
        props.colorScheme && getStyle(props.colorScheme).fillIcon} !important;
    }
  }
`;
