import React from 'react';
import PropTypes from 'prop-types';

import StyledContentWrapper from './styled-content-wrapper';
import StyledGrasWrapper from './styled-gras-wrapper';
import StyledSunWrapper from './styled-sun-wrapper';
import Text from '../../../elements/typography/text/text';

import SpinWrapper from '../spin-wrapper';
import GrowingGrasIcon from '../../../elements/icons/growing-gras-icon';
import SunIcon from '../../../elements/icons/sun-icon';

export default function LoadingView({ loadingText }) {
  return (
    <StyledContentWrapper data-cy="loading-view">
      <StyledSunWrapper>
        <SpinWrapper>
          <SunIcon />
        </SpinWrapper>
      </StyledSunWrapper>
      <StyledGrasWrapper>
        <GrowingGrasIcon />
      </StyledGrasWrapper>
      <Text>{loadingText}</Text>
    </StyledContentWrapper>
  );
}

LoadingView.defaultProps = {
  loadingText: 'Loading...'
};

LoadingView.propTypes = {
  loadingText: PropTypes.string
};
