import React from 'react';
import PropTypes from 'prop-types';

import Alert from './input_alert.svg';
import IconWrapper from '../icon-wrapper';
import { AlertIconStyle } from './styled-alert-icon';

/**
 * [See current version in abstract]
 * (https://share.goabstract.com/681627a3-420a-4b7a-8f71-49c90897d5b4)
 */

export default function AlertIcon({
  scale,
  altText,
  colorScheme,
  hover,
  active
}) {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText}>
      <AlertIconStyle
        colorScheme={colorScheme}
        hover={hover}
        active={active}
        tabIndex={1}
      >
        <Alert />
      </AlertIconStyle>
    </IconWrapper>
  );
}

AlertIcon.propTypes = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale: PropTypes.number,
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: PropTypes.string,
  /**
   * Available color schemes. If not defined, the original SVG colors will be used.
   */
  colorScheme: PropTypes.oneOf(['attention', 'negative', 'negativeShadeLight']),
  /**
   * Activate hover state on icon
   */
  hover: PropTypes.bool,
  /**
   * Activate active state on icon (clicked)
   */
  active: PropTypes.bool
};

AlertIcon.defaultProps = {
  scale: 1
};
