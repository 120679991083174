import styled from 'styled-components';
import Colors from '../../../styles/colors';
import {
  AlertIconStyle,
  AlertIconNeutral
} from '../../../elements/icons/alert-icon/styled-alert-icon';

export const Wrapper = styled.div`
  background: ${Colors.white};
  padding: 2rem;
  min-width: 80%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  & ${AlertIconStyle} {
    ${AlertIconNeutral};
  }
`;

export const AlertIconWrapper = styled.span`
  padding: 0 0 0.5rem 0;
`;

export const MetaInformationWrapper = styled.span`
  padding-top: 2.5rem;
  max-width: 16rem;
  span {
    display: inline-block;
    text-align: center;
  }
  a {
    color: ${Colors.link};
  }
`;

export const AlertIconScale = 1.4375;
