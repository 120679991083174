import colors from '../../../styles/colors';

export const getColor = (alert, warning) => {
  if (alert) {
    return colors.negativeShadeLight;
  } else if (warning) {
    return colors.attentionShadeLighter;
  } else {
    return colors.secondaryShadeLight;
  }
};

export const getInnerColor = (alert, warning) => {
  if (alert) {
    return colors.negativeShadeLightest;
  } else if (warning) {
    return colors.attentionShadeLightest;
  } else {
    return colors.secondaryShadeLightest;
  }
};
