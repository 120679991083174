/**
 * @module lib/routes
 */

import sites from './constants/sites';
import screens, { HERD_SCREENS } from './constants/screens';
import routes, { HERD_ROUTES } from './constants/routes';
import queryString from 'query-string';

const isRoute = (location, routeToCompare) => {
  return location.pathname.indexOf(routeToCompare) > -1;
};

const getHerdScreen = location => {
  if (isRoute(location, HERD_ROUTES.GROUP_REGISTRATION)) {
    return HERD_SCREENS.GROUP_REGISTRATION;
  }
  return screens.DEFAULT;
};

const getHerdRoute = screen => {
  if (screen === HERD_SCREENS.GROUP_REGISTRATION) {
    return HERD_ROUTES.GROUP_REGISTRATION;
  }
  return HERD_ROUTES.DEFAULT;
};

const getIdRoute = ({ id }) => {
  return `${routes.DEFAULT}${id}`;
};

/**
 * @description Get the screen for a given location
 *
 * @param options {object}
 * @param options.site {string}
 * @param options.location {object} - router location
 *
 * @return {string}
 */
export const getScreen = ({ site, location }) => {
  if (site === sites.HERD) {
    return getHerdScreen(location);
  }
  return screens.DEFAULT;
};

/**
 * @description Get the app internal route for a given screen
 *
 * @param options {object}
 * @param options.site {string}
 * @param options.params {object} - router params
 *
 * @return {string}
 */
export const getRoute = ({ site, screen, params, useIdRoute }) => {
  if (site === sites.HERD) {
    return getHerdRoute(screen);
  } else if (useIdRoute) {
    return getIdRoute(params);
  }
  return routes.DEFAULT;
};

/**
 * @description Get the internationalized route for a given site
 *
 * @param options {object}
 * @param options.languageTag {string}
 * @param options.site {string}
 *
 * @return {string}
 */
export const getSiteRoute = ({ languageTag, site }) => {
  return `/${require(`./constants/${languageTag}.js`).siteRoutes[site]}/`;
};

/**
 * @description Get the internationalized route for a given product
 *
 * @param options {object}
 * @param options.languageTag {string}
 * @param options.product {string}
 *
 * @return {string}
 */

export const getOtherProductsRoute = ({ languageTag, product }) => {
  return `${
    require(`./constants/${languageTag}.js`).otherProductsRoutes[product]
  }/`;
};

/**
 * @description Get the internationalized route for a screen given a site and screen
 *
 * @param options {object}
 * @param options.languageTag {string}
 * @param options.site {string}
 * @param options.screen {string}
 * @param options.params {object} - router params
 * @param options.queryParams {object} - any query params to add to the url
 *
 * @return {string}
 */
export const getScreenRoute = ({
  languageTag,
  site,
  screen,
  params,
  queryParams
}) => {
  const useIdRoute = params && params.id;

  const siteRoute = getSiteRoute({ languageTag, site });
  const screenRoute = getRoute({ site, screen, params, useIdRoute });
  const query = queryParams ? `?${queryString.stringify(queryParams)}` : '';

  return `${siteRoute}${query}#${screenRoute}`;
};
