import Colors from '../../../styles/colors';
import { size as fontSize } from '../constants/text';
import { padding } from '../constants/padding';
import Typography from '../../../styles/typography';

const buttonStyles = {
  primary: {
    backgroundColor: `${Colors.primary}`,
    textColor: `${Colors.white}`,
    borderColor: `${Colors.primary}`,
    focusBorderColor: Colors.secondary,
    hoverBackgroundColor: `${Colors.primaryShadeLight}`,
    activeBackgroundColor: `${Colors.primaryShadeDark}`,
    selectedBorderColor: `${Colors.secondary}`,
    selectedBorderWidth: '0.125rem',
    disabledBackgroundColor: `${Colors.gray}`,
    disabledTextColor: `${Colors.white}`,
    padding: `${padding.large}`,
    fontSize: `${fontSize.normal}`
  },
  secondary: {
    backgroundColor: `${Colors.secondary}`,
    textColor: `${Colors.white}`,
    borderColor: `${Colors.secondary}`,
    focusBorderColor: Colors.primary,
    hoverBackgroundColor: `${Colors.secondaryShadeMedium}`,
    activeBackgroundColor: `${Colors.secondaryShadeDark}`,
    selectedBorderColor: `${Colors.primary}`,
    selectedBorderWidth: '0.125rem',
    disabledBackgroundColor: `${Colors.gray}`,
    disabledTextColor: `${Colors.white}`,
    padding: `${padding.large}`,
    fontSize: `${fontSize.normal}`
  },
  multi: {
    backgroundColor: `${Colors.white}`,
    textColor: `${Colors.primary}`,
    borderColor: `${Colors.primary}`,
    focusBorderColor: Colors.secondary,
    hoverBackgroundColor: `${Colors.secondaryShadeLightest}`,
    activeBackgroundColor: `${Colors.secondaryShadeLight}`,
    selectedBorderColor: `${Colors.primary}`,
    selectedBorderWidth: '0.075rem',
    disabledBackgroundColor: `${Colors.lightestGray}`,
    disabledTextColor: `${Colors.darkGray}`,
    disabledBorderColor: `${Colors.gray}`,
    padding: `${padding.large}`,
    fontSize: `${fontSize.normal}`
  },
  tertiary: {
    backgroundColor: `${Colors.white}`,
    textColor: `${Colors.primary}`,
    borderColor: `${Colors.primary}`,
    focusBorderColor: Colors.secondary,
    hoverBackgroundColor: `${Colors.secondaryShadeLightest}`,
    activeBackgroundColor: `${Colors.secondaryShadeLight}`,
    selectedBorderColor: `${Colors.primary}`,
    selectedBorderWidth: '0.075rem',
    disabledBackgroundColor: `${Colors.lightestGray}`,
    disabledTextColor: `${Colors.darkGray}`,
    disabledBorderColor: `${Colors.gray}`,
    padding: `${padding.small}`,
    fontSize: `${fontSize.small}`,
    fontWeight: `${Typography.text.normal}`
  },
  warning: {
    backgroundColor: `${Colors.negative}`,
    textColor: `${Colors.white}`,
    borderColor: `${Colors.negative}`,
    focusBorderColor: `${Colors.negativeDark}`,
    hoverBackgroundColor: `${Colors.negativeShadeLight}`,
    activeBackgroundColor: `${Colors.negativeDark}`,
    selectedBorderColor: `${Colors.negativeDark}`,
    selectedBorderWidth: '0.125rem',
    disabledBackgroundColor: `${Colors.gray}`,
    disabledTextColor: `${Colors.white}`,
    padding: `${padding.large}`,
    fontSize: `${fontSize.normal}`
  }
};

export default (secondary, tertiary, multi, warning) => {
  if (secondary) {
    return buttonStyles['secondary'];
  } else if (tertiary) {
    return buttonStyles['tertiary'];
  } else if (multi) {
    return buttonStyles['multi'];
  } else if (warning) {
    return buttonStyles['warning'];
  }

  return buttonStyles['primary'];
};
