const Colors = {
  primary: '#2a3c63',
  secondary: '#209fd5',
  negative: '#ee1148',
  attention: '#f5a623',
  positive: '#06842e',
  primaryShadeDark: '#21304F',
  primaryShadeLight: '#385085',
  secondaryShadeDark: '#197faa',
  secondaryShadeMedium: '#27aedd',
  secondaryShadeLight: '#bce2f2',
  secondaryShadeLightest: '#e8f5fa',
  appColorCow: '#8000DD',
  appColorCowDark: '#6807B5',
  appColorCowLight: '#AC3AFF',
  appColorFieldAssistant: '#009986',
  appColorFieldAssistantDark: '#2C9286',
  appColorFieldAssistantLight: '#12AB98',
  negativeDark: '#c20030',
  negativeShadeLight: '#f2547b',
  negativeShadeLightest: '#ffbccd',
  attentionShadeLight: '#ffcd00',
  attentionShadeLighter: '#fed99c',
  attentionShadeLightest: '#fff4e2',
  positiveShadeLight: '#b8e986',
  black: '#000',
  offBlack: '#1c1b23',
  darkGray: '#aeaeae',
  darkestGray: '#696969',
  gray: '#cccccc',
  mediumLightGray: '#e5e5e5',
  lightGray: '#eee',
  lighterGray: '#f5f5f5',
  lightestGray: '#fafafa',
  white: '#fff',
  whiteLowTransparency: 'rgba(255, 255, 255, 0.8)',
  blackLowTransparency: 'rgba(0,0,0,0.8)',
  blackMediumTransparency: 'rgba(0,0,0,0.5)',
  blackHighTransparency: 'rgba(0, 0, 0, 0.3)',
  link: '#1f75d1'
};

/**
 * @component
 */
export default Colors;
