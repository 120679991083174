import sites from './sites';

const microFrontendPath = 'eana-microfrontend-web';

export default Object.freeze({
  [sites.HERD]: microFrontendPath,
  [sites.ANIMAL]: microFrontendPath,
  [sites.ANIMAL_NOTES]: microFrontendPath,
  [sites.KPI_PANEL]: microFrontendPath,
  [sites.DELIVERIES]: microFrontendPath,
  [sites.TEST_RESULTS]: microFrontendPath,
  [sites.FEED_SETTINGS]: microFrontendPath,
  [sites.OTHER_TOOLS]: 'livestock-othertools-web',
  [sites.REGISTER_ENROLLMENT]: microFrontendPath,
  [sites.LOGIN]: 'eana-login-web',
  [sites.SELECT_PRODUCER]: 'eana-selectproducer-web',
  [sites.REGISTER_UNENROLLMENT]: microFrontendPath,
  [sites.REGISTER_PARTURITION]: microFrontendPath,
  [sites.REGISTER_MILK_AND_CONCENTRATE_WEIGHINGS]: microFrontendPath
});
