import sites from './sites';

export default {
  '/besetning/': sites.HERD,
  '/individ/': sites.ANIMAL,
  '/individ-notat/': sites.ANIMAL_NOTES,
  '/nokkeltall/': sites.KPI_PANEL,
  '/leveringer/': sites.DELIVERIES,
  '/provesvar/': sites.TEST_RESULTS,
  '/forinnstillinger/': sites.FEED_SETTINGS,
  '/kjop/': sites.REGISTER_ENROLLMENT,
  '/utmelding/': sites.REGISTER_UNENROLLMENT,
  '/kalving/': sites.REGISTER_PARTURITION,
  '/mjolk-og-kraftfor/': sites.REGISTER_MILK_AND_CONCENTRATE_WEIGHINGS
};
