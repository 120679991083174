import styled from 'styled-components';
import { StyledContentContainer } from '../../alignment/content-container/styled-components';
import {
  AlertIconStyle,
  AlertIconNeutral
} from '../../../elements/icons/alert-icon/styled-alert-icon';

export const StyledToastMessage = styled.div`
  display: grid;
  background-color: ${(props) => props.activeColor};
  border-radius: 0.25rem;

  ${StyledContentContainer} {
    display: flex;
  }
  span {
    display: block;
  }
  span:nth-child(2) {
    margin-top: 0.5rem;
  }
  ${AlertIconStyle} {
    margin-right: 0.75rem;
    margin-top: -0.0625rem;
    ${AlertIconNeutral};
  }
`;

export const StyledToastMessageInner = styled.div`
  display: flex;
  width: 100%;
  background-color: ${(props) => props.activeColor};
  padding: 1rem;
  border-radius: 0.25rem;
`;

export const StyledContentWrapper = styled.div`
  max-width: 60rem;
`;

export const StyledRemoveButtonWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
`;

export const StyledRemoveButtonInnerWrapper = styled.div``;

export const StyledRemoveButton = styled.button`
  display: flex;
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: -0.85rem;
  margin-top: -0.85rem;
  background: none;
  box-sizing: border-box;
  padding: 0.25rem;
  outline: 0;
  border: 0;
  cursor: pointer;

  ${StyledRemoveButtonInnerWrapper} {
    display: flex;
    width: 2rem;
    height: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  :focus ${StyledRemoveButtonInnerWrapper} {
    transition: background-color 200ms ease-out;
    background-color: rgba(255, 255, 255, 0.3);
  }
  :active ${StyledRemoveButtonInnerWrapper} {
    transition: background-color 200ms ease-out;
    background-color: rgba(255, 255, 255, 1);
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
`;
