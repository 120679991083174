import React from 'react';
import PropTypes from 'prop-types';

import Sun from './sun.svg';
import IconWrapper from '../icon-wrapper';

/**
 * [See current version in abstract]
 * (https://share.goabstract.com/6ab07475-e06e-4aba-a46b-3cda01710c6a)
 */

export default function SunIcon({ scale, altText }) {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText}>
      <Sun />
    </IconWrapper>
  );
}

SunIcon.propTypes = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale: PropTypes.number,
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: PropTypes.string
};

SunIcon.defaultProps = {
  scale: 1
};
