export const HERD_ROUTES = Object.freeze({
  DEFAULT: '/',
  GROUP_REGISTRATION: '/registration'
});

export const DEFAULT_ROUTES = Object.freeze({
  DEFAULT: '/'
});

export default DEFAULT_ROUTES;
