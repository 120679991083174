/**
 * @module lib/error-boundary
 */

import React from 'react';
import * as Sentry from '@sentry/browser';

/**
 * @alias module:lib/error-boundary
 *
 * @description A component that can wrap any component sub-tree to catch
 * errors. Errors will be reported to Sentry. Run `init` first in `index.js`,
 * to initialize Sentry. You may also provide a `renderOnError` prop to
 * render your own error message.
 *
 * @example
 * // in app.js
 *
 * import ErrorBoundary from 'error-boundary';
 *
 * <ErrorBoundary>
 *   <Router />
 * </ErrorBoundary>
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    Sentry.withScope(scope => {
      Object.keys(info).forEach(key => {
        scope.setExtra(key, info[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      return this.props.renderOnError ? this.props.renderOnError() : null;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

/**
 * @description Initialize the error boundary
 *
 * @param  {object} {sentry} - sentry init options
 *
 * @example
 * // in index.js
 * import { init } from '@tine/lib-frontend-common/lib/error-boundary';
 *
 * init({
 *   sentry: {
 *     dsn: 'https://sentry.com/abc-def-123',
 *     environment: 'production'
 *   }
 * });
 */
export const init = ({ sentry }) => {
  Sentry.init(sentry);
};

/**
 * @description Capture events with Sentry
 *
 * @param {string} message
 * @param {string} level - enum: [fatal, error, warning, log, info, debug, critical]
 * @param {object} data
 *
 * @example
 * import { captureEvent } from '@tine/lib-frontend-common/lib/error-boundary';
 *
 * captureEvent(
 *  'this is unexpected behaviour',
 *  'warning',
 *  data,
 * );
 */
export const captureEvent = (message, level, data) => {
  Sentry.captureEvent({
    message,
    level,
    breadcrumbs: [{ data }]
  });
};

/**
 * @description Adds breadcrumb to sentry, useful before errors are thrown
 *
 * @param {object} breadcrumb
 *
 * object with the following keys
 * type?: string,
 * level?: Severity,
 * event_id?: string,
 * category?: string,
 * message?: string,
 * data?: any,
 * timestamp?: number,
 *
 * @example
 * import { captureEvent } from '@tine/lib-frontend-common/lib/error-boundary';
 *
 * addBreadcrumb({
 *   message: 'this is unexpected behaviour',
 *   level: 'warning',
 *   data,
 * });
 */
export const addBreadcrumb = breadcrumb => {
  Sentry.addBreadcrumb(breadcrumb);
};

/**
 * @description Capture message with Sentry
 *
 * @param {string} message
 *
 * @example
 * import { captureMessage } from '@tine/lib-frontend-common/lib/error-boundary';
 *
 * captureMessage('now this happened');
 */
export const captureMessage = message => {
  Sentry.captureMessage(message);
};
