import styled from 'styled-components';

export const StyledWrapper = styled.span`
  display: inline-block;
`;

export const StyledSpinWrapper = styled.div`
  animation: rotation 5s infinite linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
