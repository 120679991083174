import React from 'react';
import PropTypes from 'prop-types';

import Eana from './eana.svg';
import IconWrapper from '../icon-wrapper';

/**
 * [See current version in abstract]
 * ()
 */

export default function EanaIcon({ scale, altText }) {
  return (
    <IconWrapper
      width={2.5 * scale}
      height={0.8125 * scale}
      aria-label={altText}
    >
      <Eana />
    </IconWrapper>
  );
}

EanaIcon.propTypes = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale: PropTypes.number,
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: PropTypes.string
};

EanaIcon.defaultProps = {
  scale: 1
};
