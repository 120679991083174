import styled from 'styled-components';
import Colors from '../../../styles/colors/colors';

const backgroundColors = {
  contentLightGray: Colors.lightGray,
  contentLighterGray: Colors.lighterGray,
  contentLightestGray: Colors.lightestGray,
  contentWhite: Colors.white
};

const backgroundColorsTablet = Object.create(backgroundColors);
const backgroundColorsMobile = Object.create(backgroundColors);

const spacing = {
  none: '0',
  lowest: '0.5rem',
  lower: '1rem',
  low: '1.25rem',
  medium: '1.5rem',
  high: '2rem',
  higher: '2.5rem',
  highest: '3rem'
};

const spacingTablet = Object.create(spacing);
const spacingMobile = Object.create(spacing);

export const StyledContentContainer = styled.div`
  background-color: ${(props) =>
    props.styling && backgroundColors[props.styling.backgroundColor]};

  padding: ${(props) =>
    props.styling && spacing[props.styling.paddingAllSides]};
  padding-top: ${(props) => props.styling && spacing[props.styling.paddingTop]};
  padding-bottom: ${(props) =>
    props.styling && spacing[props.styling.paddingBottom]};
  padding-left: ${(props) =>
    props.styling && spacing[props.styling.paddingLeft]};
  padding-right: ${(props) =>
    props.styling && spacing[props.styling.paddingRight]};

  margin: ${(props) => props.styling && spacing[props.styling.marginAllSides]};
  margin-top: ${(props) => props.styling && spacing[props.styling.marginTop]};
  margin-bottom: ${(props) =>
    props.styling && spacing[props.styling.marginBottom]};
  margin-left: ${(props) => props.styling && spacing[props.styling.marginLeft]};
  margin-right: ${(props) =>
    props.styling && spacing[props.styling.marginRight]};

  @media (min-width: 1024px) {
    background-color: ${(props) =>
      props.styling &&
      backgroundColors[props.styling.backgroundColorDesktopOnly]};

    padding: ${(props) =>
      props.styling && spacing[props.styling.paddingAllSidesDesktopOnly]};
    padding-top: ${(props) =>
      props.styling && spacing[props.styling.paddingTopDesktopOnly]};
    padding-bottom: ${(props) =>
      props.styling && spacing[props.styling.paddingBottomDesktopOnly]};
    padding-left: ${(props) =>
      props.styling && spacing[props.styling.paddingLeftDesktopOnly]};
    padding-right: ${(props) =>
      props.styling && spacing[props.styling.paddingRightDesktopOnly]};

    margin: ${(props) =>
      props.styling && spacing[props.styling.marginAllSidesDesktopOnly]};
    margin-top: ${(props) =>
      props.styling && spacing[props.styling.marginTopDesktopOnly]};
    margin-bottom: ${(props) =>
      props.styling && spacing[props.styling.marginBottomDesktopOnly]};
    margin-left: ${(props) =>
      props.styling && spacing[props.styling.marginLeftDesktopOnly]};
    margin-right: ${(props) =>
      props.styling && spacing[props.styling.marginRightDesktopOnly]};
  }

  @media (min-width: 600px) and (max-width: 1023px) {
    background-color: ${(props) =>
      props.styling && backgroundColorsTablet[props.styling.backgroundColor]};
    background-color: ${(props) =>
      props.styling &&
      backgroundColorsTablet[props.styling.backgroundColorTabletOnly]};

    padding: ${(props) =>
      props.styling && spacingTablet[props.styling.paddingAllSides]};
    padding: ${(props) =>
      props.styling && spacingTablet[props.styling.paddingAllSidesTabletOnly]};
    padding-top: ${(props) =>
      props.styling && spacingTablet[props.styling.paddingTop]};
    padding-top: ${(props) =>
      props.styling && spacingTablet[props.styling.paddingTopTabletOnly]};
    padding-bottom: ${(props) =>
      props.styling && spacingTablet[props.styling.paddingBottom]};
    padding-bottom: ${(props) =>
      props.styling && spacingTablet[props.styling.paddingBottomTabletOnly]};
    padding-left: ${(props) =>
      props.styling && spacingMobile[props.styling.paddingLeft]};
    padding-left: ${(props) =>
      props.styling && spacingTablet[props.styling.paddingLeftTabletOnly]};
    padding-right: ${(props) =>
      props.styling && spacingTablet[props.styling.paddingRight]};
    padding-right: ${(props) =>
      props.styling && spacingTablet[props.styling.paddingRightTabletOnly]};

    margin: ${(props) =>
      props.styling && spacingTablet[props.styling.marginAllSides]};
    margin: ${(props) =>
      props.styling && spacingTablet[props.styling.marginAllSidesTabletOnly]};
    margin-top: ${(props) =>
      props.styling && spacingTablet[props.styling.marginTop]};
    margin-top: ${(props) =>
      props.styling && spacingTablet[props.styling.marginTopTabletOnly]};
    margin-bottom: ${(props) =>
      props.styling && spacingTablet[props.styling.marginBottom]};
    margin-bottom: ${(props) =>
      props.styling && spacingTablet[props.styling.marginBottomTabletOnly]};
    margin-left: ${(props) =>
      props.styling && spacingTablet[props.styling.marginLeft]};
    margin-left: ${(props) =>
      props.styling && spacingTablet[props.styling.marginLeftTabletOnly]};
    margin-right: ${(props) =>
      props.styling && spacingTablet[props.styling.marginRight]};
    margin-right: ${(props) =>
      props.styling && spacingTablet[props.styling.marginRightTabletOnly]};
  }

  @media (max-width: 599px) {
    background-color: ${(props) =>
      props.styling && backgroundColorsMobile[props.styling.backgroundColor]};
    background-color: ${(props) =>
      props.styling &&
      backgroundColorsMobile[props.styling.backgroundColorMobileOnly]};

    padding: ${(props) =>
      props.styling && spacingMobile[props.styling.paddingAllSides]};
    padding: ${(props) =>
      props.styling && spacingMobile[props.styling.paddingAllSidesMobileOnly]};
    padding-top: ${(props) =>
      props.styling && spacingMobile[props.styling.paddingTop]};
    padding-top: ${(props) =>
      props.styling && spacingMobile[props.styling.paddingTopMobileOnly]};
    padding-bottom: ${(props) =>
      props.styling && spacingMobile[props.styling.paddingBottom]};
    padding-bottom: ${(props) =>
      props.styling && spacingMobile[props.styling.paddingBottomMobileOnly]};
    padding-left: ${(props) =>
      props.styling && spacingMobile[props.styling.paddingLeft]};
    padding-left: ${(props) =>
      props.styling && spacingMobile[props.styling.paddingLeftMobileOnly]};
    padding-right: ${(props) =>
      props.styling && spacingMobile[props.styling.paddingRight]};
    padding-right: ${(props) =>
      props.styling && spacingMobile[props.styling.paddingRightMobileOnly]};

    margin: ${(props) =>
      props.styling && spacingMobile[props.styling.marginAllSides]};
    margin: ${(props) =>
      props.styling && spacingMobile[props.styling.marginAllSidesMobileOnly]};
    margin-top: ${(props) =>
      props.styling && spacingMobile[props.styling.marginTop]};
    margin-top: ${(props) =>
      props.styling && spacingMobile[props.styling.marginTopMobileOnly]};
    margin-bottom: ${(props) =>
      props.styling && spacingMobile[props.styling.marginBottom]};
    margin-bottom: ${(props) =>
      props.styling && spacingMobile[props.styling.marginBottomMobileOnly]};
    margin-left: ${(props) =>
      props.styling && spacingMobile[props.styling.marginLeft]};
    margin-left: ${(props) =>
      props.styling && spacingMobile[props.styling.marginLeftMobileOnly]};
    margin-right: ${(props) =>
      props.styling && spacingMobile[props.styling.marginRight]};
    margin-right: ${(props) =>
      props.styling && spacingMobile[props.styling.marginRightMobileOnly]};
  }
`;
