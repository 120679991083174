import React, { useState, useEffect } from 'react';

import LoadingView from '@tine/lib-frontend-components/components/animations/loading-view';
import ErrorPage from '@tine/lib-frontend-components/components/errors/error-page';

import { useAuth0 } from '@auth0/auth0-react';

import { AuthUserContext } from '../auth-user-context';
import decodeToken from '../decode-token';

import lang from '../constants/lang';
import storageKeys from '../constants/storage-keys';
import defaultConnection from '../constants/default-connection';

import redirectOrAuthorize from './redirect-or-authorize';
import getProducerId from './get-producer-id';
import getActorId from './get-actor-id';
import getName from './get-name';
import getProducerCode from './get-producer-code';
import getRoles from './get-roles';
import getEanaCookieConsent from './get-eana-cookie-consent';

const expiredKey = 'exp';

const logoutAuth0 = (runWithoutAuthorization, authConfig, logout, options) => {
  if (runWithoutAuthorization) {
    localStorage.clear();
    window.location.replace(
      `https://${authConfig.auth0Domain}/v2/logout?federated&returnTo=${options.returnTo}` //NOSONAR
    );
  } else {
    logout(options);
  }
};

export default function (
  authConfig,
  WrappedComponent,
  runWithoutAuthorization = false
) {
  return () => {
    const {
      error,
      isAuthenticated,
      isLoading,
      getAccessTokenSilently,
      getIdTokenClaims,
      loginWithRedirect,
      logout
    } = useAuth0();

    const [accessToken, setAccessToken] = useState(null);
    const [idToken, setIdToken] = useState(null);
    const [roles, setRoles] = useState(null);
    const [producerId, setProducerId] = useState(null);
    const [producerCode, setProducerCode] = useState(null);
    const [actorId, setActorId] = useState(null);
    const [name, setName] = useState(null);
    const [eanaCookieConsent, setEanaCookieConsent] = useState(null);

    const authorize = (connection = defaultConnection) => {
      loginWithRedirect({
        connection
      });
    };

    useEffect(() => {
      if (isAuthenticated) {
        async function fetchAccessToken() {
          const token = await getAccessTokenSilently();

          const parsedToken = decodeToken(token);

          localStorage.setItem(storageKeys.accessToken, token);
          localStorage.setItem(storageKeys.expiresAt, parsedToken[expiredKey]);
          setAccessToken(token);
        }

        fetchAccessToken();
      }
    }, [isAuthenticated]);

    useEffect(() => {
      if (isAuthenticated) {
        async function fetchIdToken() {
          const token = await getIdTokenClaims();
          localStorage.setItem(storageKeys.idToken, token.__raw);
          setIdToken(token);
        }

        fetchIdToken();
      }
    }, [isAuthenticated]);

    useEffect(() => {
      if (runWithoutAuthorization) {
        setAccessToken(localStorage.getItem(storageKeys.accessToken));
        setIdToken(localStorage.getItem(storageKeys.idToken));
      }
    }, [runWithoutAuthorization]);

    useEffect(() => {
      if (isAuthenticated || runWithoutAuthorization) {
        setRoles(getRoles());
        setProducerId(getProducerId());
        setProducerCode(getProducerCode());
        setActorId(getActorId());
        setName(getName());
        setEanaCookieConsent(getEanaCookieConsent());
      }
    }, [isAuthenticated, accessToken, runWithoutAuthorization]);

    if (!runWithoutAuthorization && isLoading) {
      return <LoadingView loadingText={lang.LOADING} />;
    }

    if (!runWithoutAuthorization && error) {
      return (
        <ErrorPage
          header={lang.ERROR_BOUNDARY_HEADER}
          body={lang.ERROR_BOUNDARY_BODY}
          metaInformation={lang.ERROR_BOUNDARY_META_INFORMATION}
        />
      );
    }

    return (
      <AuthUserContext.Provider
        value={{
          accessToken,
          idToken,
          producerId,
          actorId,
          name,
          producerCode,
          roles,
          eanaCookieConsent
        }}
      >
        <WrappedComponent
          {...{
            loggedIn: isAuthenticated || runWithoutAuthorization,
            logout: (options) =>
              logoutAuth0(runWithoutAuthorization, authConfig, logout, options),
            onAuthorize: (connection) => authorize(connection),
            onRedirectOrAuthorize: (config) =>
              redirectOrAuthorize(config, loginWithRedirect)
          }}
        />
      </AuthUserContext.Provider>
    );
  };
}
