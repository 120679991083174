import React from 'react';
import PropTypes from 'prop-types';

import MimiroVertical from './mimiro-vertical.svg';
import IconWrapper from '../icon-wrapper';

import { MimiroVerticalIconStyle } from './styled-components';

export default function MimiroVerticalIcon({ scale, altText, colorScheme }) {
  return (
    <IconWrapper
      width={2.0625 * scale}
      height={2.25 * scale}
      aria-label={altText}
    >
      <MimiroVerticalIconStyle colorScheme={colorScheme}>
        <MimiroVertical />
      </MimiroVerticalIconStyle>
    </IconWrapper>
  );
}

MimiroVerticalIcon.propTypes = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale: PropTypes.number,
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: PropTypes.string,
  /**
   * Available color schemes. If not defined, the original SVG colors will be used.
   */
  colorScheme: PropTypes.oneOf(['white', 'black', 'negative'])
};

MimiroVerticalIcon.defaultProps = {
  scale: 1
};
