import decodeToken from '../decode-token';

import storageKeys from '../constants/storage-keys';

const getIdToken = () => {
  return localStorage.getItem(storageKeys.idToken);
};

export default (key) => {
  const token = getIdToken();

  if (token && token !== '') {
    try {
      const parsedIdToken = decodeToken(token);
      return parsedIdToken[key];
    } catch (e) {
      console.info(
        `Unable to decode id token in AuthClient#getIdTokenInformation with key=${key}: ${
          e && e.message
        }`
      );
    }
  } else {
    return null;
  }
};
