import getAccessTokenInformation from './get-access-token-information';

const tineProducerIdKey = 'https://openfarm.io/c/tineCowMilkProducerId';
const qProducerIdKey = 'https://openfarm.io/c/qCowMilkProducerId';

export default () => {
  return (
    getAccessTokenInformation(tineProducerIdKey) ||
    getAccessTokenInformation(qProducerIdKey)
  );
};
