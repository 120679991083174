import styled from 'styled-components';

import Typography from '../../../styles/typography';

const backgroundColor = (color) => (props) =>
  props.disabled ? props.style.disabledBackgroundColor : props.style[color];

export default styled.button`
  box-sizing: border-box;
  font-size: ${(props) => props.style.fontSize};
  font-family: ${Typography.text.name};
  font-weight: ${(props) =>
    props.style.fontWeight ? props.style.fontWeight : Typography.text.semibold};
  text-align: center;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border: thin solid
    ${(props) =>
      props.disabled
        ? props.style.disabledBorderColor
        : props.style.borderColor} !important;
  border-radius: 1.5rem;
  background-color: ${backgroundColor('backgroundColor')} !important;
  color: ${(props) =>
    props.disabled
      ? props.style.disabledTextColor
      : props.style.textColor} !important;
  padding: ${(props) => props.style.padding};
  width: ${(props) => props.width};
  &:hover {
    background-color: ${backgroundColor('hoverBackgroundColor')} !important;
  }
  &:active {
    background-color: ${backgroundColor('activeBackgroundColor')} !important;
    border-color: ${backgroundColor('activeBackgroundColor')} !important;
  }
  &:focus {
    outline: 0;
    box-shadow: ${(props) => props.style.focusBorderColor} 0 0 0 0.125rem;
  }
  ${(props) =>
    props.selected &&
    `box-shadow: ${props.style.selectedBorderColor} 0 0 0.1rem ${props.style.selectedBorderWidth};`};
`;
