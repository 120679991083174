import React from 'react';
import PropTypes from 'prop-types';
import { StyledAppContainer } from './styled-components';
import Colors from '../../../styles/colors';

/**
 *
 */

export default function AppContainer(props) {
  return (
    <StyledAppContainer
      appBackgroundColors={props.appBackgroundColors}
      className="app-container"
    >
      {props.children}
    </StyledAppContainer>
  );
}

AppContainer.defaultProps = {
  appBackgroundColors: {
    mobile: Colors.white,
    tablet: Colors.white,
    web: Colors.white
  }
};

AppContainer.propTypes = {
  children: PropTypes.node,
  appBackgroundColors: PropTypes.shape({
    mobile: PropTypes.oneOf([
      Colors.white,
      Colors.lightGray,
      Colors.lighterGray,
      Colors.lightestGray
    ]).isRequired,
    tablet: PropTypes.oneOf([
      Colors.white,
      Colors.lightGray,
      Colors.lighterGray,
      Colors.lightestGray
    ]).isRequired,
    web: PropTypes.oneOf([
      Colors.white,
      Colors.lightGray,
      Colors.lighterGray,
      Colors.lightestGray
    ]).isRequired
  })
};
