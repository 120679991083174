import React from 'react';
import PropTypes from 'prop-types';
import { StyledContentContainer } from './styled-components';
/**
 * The ContentContainer can be used to wrap around content such as texts, images and so on and allow you to set
 * margin, padding or background color on all or some devices.
 * If you see yourself using many instances of the ContentContainer on the same type of content with the same settings,
 * your should consider making a new layout component that extends the funtionality of this one, where you set the default props.
 *
 * Note: The values 'lowest' 'lower', 'low', 'medium', 'high', 'higher', 'highest' is set to the same values accross all devices.
 * Not like in SectionContainer where they are a set to different values based on the screen size.
 * They are still set up to take different values here as well, if we ever want to scale up the values on diffent screen widths.
 */

export default function ContentContainer(props) {
  return (
    <StyledContentContainer className={props.className} styling={props.styling}>
      {props.children}
    </StyledContentContainer>
  );
}

ContentContainer.propTypes = {
  /** Content that should be wrapped with this container */
  children: PropTypes.node,
  styling: PropTypes.shape({
    /** Sets bakground-color for all devices. One of 'contentWhite', 'contentLightestGray', 'contentLighterGray', 'contentLightGray' (Same goes for background overrides bellow)*/
    backgroundColor: PropTypes.oneOf([
      'contentWhite',
      'contentLightestGray',
      'contentLighterGray',
      'contentLightGray'
    ]),
    /** Overrides / sets background-color on desktop only*/
    backgroundColorDesktopOnly: PropTypes.oneOf([
      'contentWhite',
      'contentLightestGray',
      'contentLighterGray',
      'contentLightGray'
    ]),
    /** Overrides / sets background-color on tablet only*/
    backgroundColorTabletOnly: PropTypes.oneOf([
      'contentWhite',
      'contentLightestGray',
      'contentLighterGray',
      'contentLightGray'
    ]),
    /** Overrides / sets background-color on mobile only*/
    backgroundColorMobileOnly: PropTypes.oneOf([
      'contentWhite',
      'contentLightestGray',
      'contentLighterGray',
      'contentLightGray'
    ]),
    /** Sets padding on all sides. One of 'none' 'lowest' 'lower', 'low', 'medium', 'high', 'higher', 'highest' (Same goes for all padding options bellow)*/
    paddingAllSides: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Sets padding-top for every device*/
    paddingTop: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Sets padding-bottom for every device*/
    paddingBottom: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Sets padding-left for every device*/
    paddingLeft: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Sets padding-right for every device*/
    paddingRight: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets padding all sides on desktop*/
    paddingAllSidesDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets padding-top on desktop*/
    paddingTopDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets padding-bottom on desktop*/
    paddingBottomDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets padding-left on desktop*/
    paddingLeftDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets padding-right on desktop*/
    paddingRightDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets padding all sides on mobile*/
    paddingAllSidesMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets padding-top on mobile*/
    paddingTopMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets padding-bottom on mobile*/
    paddingBottomMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets padding-left on mobile*/
    paddingLeftMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets padding-right on mobile*/
    paddingRightMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets padding all sides on tablet*/
    paddingAllSidesTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets padding-top on tablet*/
    paddingTopTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets padding-bottom on tablet*/
    paddingBottomTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets padding-left on tablet*/
    paddingLeftTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets padding-right on tablet*/
    paddingRightTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Sets margin on all sides. One of 'none' 'lowest' 'lower', 'low', 'medium', 'high', 'higher', 'highest' (Same goes for all margin options bellow)*/
    marginAllSides: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Sets margin-top for every device*/
    marginTop: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Sets margin-bottom for every device*/
    marginBottom: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Sets margin-left for every device*/
    marginLeft: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Sets margin-right for every device*/
    marginRight: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets margin all sides on desktop*/
    marginAllSidesDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets margin-top on desktop*/
    marginTopDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets margin-bottom on desktop*/
    marginBottomDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets margin-left on desktop*/
    marginLeftDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets margin-right on desktop*/
    marginRightDesktopOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets margin all sides on tablet*/
    marginAllSidesTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets margin-top on tablet*/
    marginTopTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets margin-bottom on tablet*/
    marginBottomTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets margin-left on tablet*/
    marginLeftTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets margin-right on tablet*/
    marginRightTabletOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets margin all sides on mobile*/
    marginAllSidesMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets margin-top on mobile*/
    marginTopMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets margin-bottom on mobile*/
    marginBottomMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets margin-left on mobile*/
    marginLeftMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ]),
    /** Overrides / sets margin-right on mobile*/
    marginRightMobileOnly: PropTypes.oneOf([
      'none',
      'lowest',
      'lower',
      'low',
      'medium',
      'high',
      'higher',
      'highest'
    ])
  })
};
