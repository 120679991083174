import qs from 'qs';

import { getScreenRoute } from '@tine/lib-frontend-eana/lib/routes';
import sites from '@tine/lib-frontend-eana/lib/constants/sites';
import screens from '@tine/lib-frontend-eana/lib/constants/screens';

const redirectUserToLogin = (config) => {
  const after_login = window.location.href;
  const route = getScreenRoute({
    languageTag: config.languageTag,
    site: sites.LOGIN,
    screen: screens.DEFAULT,
    queryParams: {
      after_login: encodeURI(after_login)
    }
  });
  window.location.href = `${route}`;
};

export default (config, loginWithRedirect) => {
  const queryString = window.location.search;
  if (queryString.indexOf('source=sdb') > -1) {
    const queryParams = qs.parse(queryString, { ignoreQueryPrefix: true });
    const connection = queryParams['connection'];
    loginWithRedirect(connection);
  } else if (window.location.href.indexOf('localhost:5000') > -1) {
    loginWithRedirect('');
  } else {
    redirectUserToLogin(config);
  }
};
