import styled from 'styled-components';

import Colors from '../../../styles/colors/colors';

export default styled.button`
  border-style: none;
  background-color: transparent;
  color: ${(props) => (props.disabled ? Colors.gray : Colors.black)};
  padding: 0;
  cursor: pointer;
  &:hover,
  &:active {
    color: ${(props) => (props.disabled ? Colors.gray : Colors.secondary)};
    fill: ${(props) => (props.disabled ? Colors.gray : Colors.secondary)};
  }
  &:focus {
    outline: 0;
  }
`;
