import React from 'react';

import { Auth0Provider } from '@auth0/auth0-react';

import storageKeys from './constants/storage-keys';

import withAuth0Data from './with-auth0-data';

export default function (authConfig, WrappedComponent) {
  return () => {
    const hasNotExpired = () => {
      const expiresAt = JSON.parse(
        localStorage.getItem(storageKeys.expiresAt) || 0
      );
      return Math.round(new Date().getTime() / 1000) < expiresAt;
    };

    if (hasNotExpired()) {
      const WithData = withAuth0Data(authConfig, WrappedComponent, true);

      return <WithData />;
    } else {
      const WithData = withAuth0Data(authConfig, WrappedComponent);

      return (
        <Auth0Provider
          domain={authConfig.auth0Domain}
          clientId={authConfig.clientId}
          redirectUri={authConfig.redirectUri}
          audience={authConfig.audience}
        >
          <WithData />
        </Auth0Provider>
      );
    }
  };
}
