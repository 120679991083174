import React from 'react';
import PropTypes from 'prop-types';

import EmphasisLight from '../../../elements/typography/text/emphasis-light';
import Text from '../../../elements/typography/text';
import ContentContainer from '../../alignment/content-container';
import CrossIcon from '../../../elements/icons/cross-icon';
import AlertIcon from '../../../elements/icons/alert-icon';
import { AlertIconStyle } from '../../../elements/icons/alert-icon/styled-alert-icon';

import {
  StyledToastMessage,
  StyledToastMessageInner,
  StyledContentWrapper,
  StyledRemoveButtonInnerWrapper,
  StyledRemoveButton,
  StyledRemoveButtonWrapper,
  IconWrapper
} from './styled-components';

import { getColor, getInnerColor } from './get-color';

/**
 * The component takes props for the title and message that should be displayed in the dialog.
 *
 * By default the warning level is natural but can be with prop to 'warning' or 'alert' which changes the colors.
 *
 * There is also props for showing the icon and or the remove button, which is set to true by default.
 */

const ToastMessage = function (props) {
  const {
    alert,
    warning,
    title,
    message,
    showIcon,
    showRemoveButton,
    onClick
  } = props;
  const activeColor = getColor(alert, warning);
  const activeInnerColor = getInnerColor(alert, warning);

  return (
    <StyledToastMessage activeColor={activeColor}>
      <ContentContainer styling={{ paddingAllSides: 'lowest' }}>
        {showIcon ? (
          <IconWrapper>
            <AlertIconStyle>
              <AlertIcon scale={0.7} />
            </AlertIconStyle>
          </IconWrapper>
        ) : null}
        <StyledToastMessageInner activeColor={activeInnerColor}>
          <StyledContentWrapper>
            {title ? <EmphasisLight>{title}</EmphasisLight> : null}
            <Text size="small">
              {<span dangerouslySetInnerHTML={{ __html: message }} />}
            </Text>
          </StyledContentWrapper>
          <StyledRemoveButtonWrapper>
            <StyledRemoveButton
              data-cy="remove-message-button"
              role="button"
              aria-pressed="false"
              aria-label="Kryss bort"
              onClick={() => onClick()}
            >
              <StyledRemoveButtonInnerWrapper>
                {showRemoveButton ? <CrossIcon scale={0.4} /> : null}
              </StyledRemoveButtonInnerWrapper>
            </StyledRemoveButton>
          </StyledRemoveButtonWrapper>
        </StyledToastMessageInner>
      </ContentContainer>
    </StyledToastMessage>
  );
};

ToastMessage.defaultProps = {
  showIcon: true,
  showRemoveButton: true
};

ToastMessage.propTypes = {
  /** Title in toast. */
  title: PropTypes.string,
  /** Message in toast. */
  message: PropTypes.string.isRequired,
  /** Display icon to the left of the toast. */
  showIcon: PropTypes.bool,
  /** Display the cross button on the top right corner of the toast. */
  showRemoveButton: PropTypes.bool,
  /** If given the toast would have the warning styling. */
  warning: PropTypes.bool,
  /** If given the toast would have the alert styling. */
  alert: PropTypes.bool,
  /** Function that would be called when the cross icon is beeing clicked. */
  onClick: PropTypes.func
};

export default ToastMessage;
