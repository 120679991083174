import React from 'react';
import PropTypes from 'prop-types';
import AlertIcon from '../../../elements/icons/alert-icon';
import Emphasis from '../../../elements/typography/text/emphasis';
import Text from '../../../elements/typography/text';
import MetaInformation from '../../../elements/typography/text/meta-information';
import {
  AlertIconScale,
  AlertIconWrapper,
  MetaInformationWrapper,
  Wrapper
} from './styled';

/**
 * [See current version in abstract]
 * (https://share.goabstract.com/a23143b6-be6a-441e-8e6d-7c9396def7b0?mode=design&sha=be2a7c565d74ae7a80e7b82bb369b0ab10a97616)
 *
 * The ErrorPage is meant as a generic error component when unable to show context
 */
const ErrorPage = ({ header, body, metaInformation }) => {
  return (
    <Wrapper>
      <AlertIconWrapper>
        <AlertIcon scale={AlertIconScale} />
      </AlertIconWrapper>
      <Emphasis size="large">{header}</Emphasis>
      <Text size="large">
        <span dangerouslySetInnerHTML={{ __html: body }} />
      </Text>
      <MetaInformationWrapper>
        <MetaInformation size="small" italic>
          <span dangerouslySetInnerHTML={{ __html: metaInformation }} />
        </MetaInformation>
      </MetaInformationWrapper>
    </Wrapper>
  );
};

ErrorPage.propTypes = {
  header: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  metaInformation: PropTypes.string.isRequired
};

export default ErrorPage;
