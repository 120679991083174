import React from 'react';
import PropTypes from 'prop-types';

import StyledClickableIcon from '../common/styled-clickable-icon';

const StyledButton = StyledClickableIcon.withComponent('button');

/**
 * The IconButton component wraps an Icon and provides a button.
 *
 * Any SVG provided in the icon should have a fill set to currentColor, not a specific color value.
 *
 */
export default function IconButton({ children, onClick, disabled }) {
  return (
    <StyledButton data-cy="icon-button" onClick={onClick} disabled={disabled}>
      {children}
    </StyledButton>
  );
}

IconButton.propTypes = {
  /**
   * Handler to be called when the button is clicked.
   */
  onClick: PropTypes.func,
  /**
   * If true it would set the disabled style on the button.
   */
  disabled: PropTypes.bool
};
